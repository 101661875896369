import { IS_DEVELOPMENT } from 'configs/index'
import useAuth from 'hooks/authentication/useAuth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ampliClient, loadAmplitudeClient } from 'services/analytics/amplitude'
import { setUpUsetiful } from 'services/analytics/usetiful'
import { useLocalStorage } from 'usehooks-ts'

const InitialSetupController = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [cookieConsent, setCookieConsent] = useLocalStorage<boolean | null>('cookie_consent', true) // TODO: change to null to show cookie banner

  const { authUser: myProfile, isUserSegmentationPopupVisible } = useAuth()

  const onAcceptAllClick = useCallback(() => setCookieConsent(true), [setCookieConsent])
  const onAcceptNecessaryClick = useCallback(() => setCookieConsent(false), [setCookieConsent])

  const router = useRouter()

  // Ampltude
  useEffect(() => {
    if (!ampliClient.isLoaded) {
      loadAmplitudeClient({
        optOut: IS_DEVELOPMENT,
        minIdLength: 1,
      })

      return
    }

    if (!myProfile) return

    const {
      id,
      email,
      country,
      city,
      role,
      state,
      firstName,
      lastName,
      username,
      website,
      signupType,
      totalAmountSpent,
      totalArtworkAmountSold,
      totalPurchases,
      uploadedArtworkCounts,
      isVerified,
      artworkSoldCounts,
      createdAt,
    } = myProfile
    ampliClient.identify(id?.toString(), {
      user_id: String(id),
      country,
      extra: {
        user_role: role,
        signup_type: signupType,
        email,
        city,
        state,
        first_name: firstName,
        last_name: lastName,
        username,
        website,
        total_amount_spent: totalAmountSpent || 0,
        total_artwork_amount_sold: totalArtworkAmountSold || 0,
        total_purchases: totalPurchases || 0,
        total_artwork_uploaded: uploadedArtworkCounts || 0,
        total_artwork_sold: artworkSoldCounts || 0,
        user_verfied: isVerified,
        signup_at: createdAt,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cookieConsent,
    myProfile?.id,
    myProfile?.uploadedArtworkCounts,
    myProfile?.totalAmountSpent,
    myProfile?.totalArtworkAmountSold,
    myProfile?.totalPurchases,
    myProfile?.artworkSoldCounts,
    myProfile?.isVerified,
    myProfile?.role,
    myProfile?.city,
    myProfile?.state,
    myProfile?.country,
    myProfile?.firstName,
    myProfile?.lastName,
    myProfile?.username,
    myProfile?.website,
  ])

  // Gtag
  useEffect(() => {
    if (typeof window.gtag === 'undefined') return
    window.gtag('consent', 'update', {
      analytics_storage: 'granted',
    })

    if (!myProfile) return

    const { id, country, city, state, firstName, lastName, username, website } = myProfile
    window.gtag('set', 'user_id', id)
    window.gtag('set', 'user_properties', {
      country,
      city,
      state,
      firstName,
      lastName,
      username,
      website,
    })
  }, [cookieConsent, myProfile])

  // Usetifull
  useEffect(() => {
    if (!myProfile) return

    setUpUsetiful({ myProfile, isMobile, hasChosenRole: !isUserSegmentationPopupVisible })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    myProfile?.id,
    myProfile?.role,
    myProfile?.isVerified,
    myProfile?.uploadedArtworkCounts,
    myProfile?.coverImage,
    myProfile?.flags?.hasUpdatedAddress,
    myProfile?.flags?.hasUpdatedUsername,
    myProfile?.flags?.hasSharedStorefront,
    isMobile,
    isUserSegmentationPopupVisible,
  ])

  if (cookieConsent !== null || router.route === '/terms') return null
  if (cookieConsent) return null // TODO: remove this line to show cookie banner

  return (
    <div className=" fixed bottom-0 left-0 z-50 flex h-[124px] w-full flex-col items-center justify-between bg-[#FDFDFDCC] px-10 py-5 backdrop-blur-lg lg:h-[88px] lg:flex-row lg:py-0">
      <p className="text-xs lg:text-base">
        We use cookies to deliver a better and personlized experience for you. Read our{' '}
        <Link passHref href="/privacy-policy">
          <strong>Privacy Policy</strong>
        </Link>{' '}
        for more information.
      </p>
      <div className="flex items-center gap-8">
        <button className="text-xs lg:text-base" onClick={onAcceptNecessaryClick}>
          <strong>Accept necessary</strong>
        </button>
        <button
          className="rounded-full border border-black bg-white px-6 py-1 text-xs font-semibold lg:text-base"
          onClick={onAcceptAllClick}
        >
          Accept all
        </button>
      </div>
    </div>
  )
}

export default InitialSetupController
