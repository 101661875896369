import Container from './Container'

const UnderConstruction = () => {
  return (
    <Container className="flex h-full flex-col justify-between">
      <div className="invisible" />
      <div className="grid place-items-center text-center">
        <h1
          className="font-beni text-[calc(170.26%*8.5)] uppercase lg:text-[calc(200.26%*8.5)]"
          style={{ lineHeight: '100%', marginTop: 13 }}
        >
          Cohart
        </h1>
        <p className="text-2xl">The website is currently under maintenance</p>
        <p className="text-2xl">Sorry for any inconvenience. We'll be back soon!</p>
      </div>
    </Container>
  )
}

export default UnderConstruction
