import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import updateProfile from 'services/me/updateProfile'
import useShowingInfoOnce from 'hooks/useShowingInfoOnce'
import dynamic from 'next/dynamic'
import { notifyPromise, notifySuccess } from 'utils/toast'
import useAuth from 'hooks/authentication/useAuth'
import useCheckingRole from './data-fetching/useCheckingRole'
import { useConfirmModal } from 'hooks/common/useConfirmModal'
import applyToBeCollector from 'views/authentication/services/applyToBeCollector'
import { useRouter } from 'next/router'
import { ApplyToBeCollectorType } from 'views/authentication/types'
import { identifyAmplitudeUser, trackAmplitudeCustomEvent } from 'services/analytics/amplitude'

const SegmentationModal = dynamic(() => import('shared/components/modal/SegmentationModal'))
const AreYouArtistModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouArtistModal'))
const AreYouCollectorModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouCollectorModal'))
const AreYouAdvisorModal = dynamic(() => import('shared/components/modal/ChoosingRoleModal/AreYouAdvisorModal'))
const WaitListCongratulationModal = dynamic(() => import('shared/components/modal/WaitListCongratulationModal'), {
  ssr: false,
})

const ApplyToBeCohartAdvisorModal = dynamic(
  () => import('views/advisor-artists/components/ApplyToBeCohartAdvisorModal'),
)

type AskingRoleModalsContextType = {
  showAreYouArtistModal: () => void
  showAreYouCollectorModal: () => void
  showAreYouAdvisorModal: () => void
  showApplyToBeCohartAdvisorModal: () => void
  setIsAskingRoleBannerClosed: (value: boolean) => void
  isAskingRoleBannerClosed: boolean
  setIsApplyCohartAdvisorBannerClosed: (value: boolean) => void
  isApplyCohartAdvisorBannerClosed: boolean
}

const AskingRoleModalsContext = createContext<AskingRoleModalsContextType>({} as AskingRoleModalsContextType)

type AskingRoleModalsProviderProps = {
  children: ReactNode
}

type RoleName = 'Artist' | 'Collector' | 'Advisor' | 'Cohart Advisor' | 'Henry'

export const AskingRoleModalsProvider = ({ children }: AskingRoleModalsProviderProps) => {
  const {
    authUser,
    mutateAuth,
    isUserSegmentationPopupVisible,
    shouldRedirectAfterSegmentation,
    handleUserSegmentationPopup,
  } = useAuth()
  const { isUserCA, isUserIA, isUserCreator, isUserCollector, isUserHenry } = useCheckingRole(authUser?.role)
  const router = useRouter()

  const currentRoleName: RoleName | null = useMemo(() => {
    if (isUserHenry) return 'Henry'
    if (isUserCreator) return 'Artist'
    if (isUserCollector) return 'Collector'
    if (isUserCA) return 'Cohart Advisor'
    if (isUserIA) return 'Advisor'
    return null // if the user's role is not fetched yet
  }, [isUserCA, isUserCollector, isUserCreator, isUserHenry, isUserIA])

  const {
    value: isAreYouArtistModalOpen,
    setTrue: showAreYouArtistModal,
    setFalse: hideAreYouArtistModal,
  } = useBoolean(false)

  const {
    value: isCongratCollectorWaitlistModalVisible,
    setTrue: showCongratCollectorWaitlistModal,
    setFalse: closeCongratCollectorWaitlistModal,
  } = useBoolean(false)

  const [collectorWaitlistPosition, setCollectorWaitlistPosition] = useState(1)

  const {
    value: isAreYouCollectorModalOpen,
    setTrue: showAreYouCollectorModal,
    setFalse: hideAreYouCollectorModal,
  } = useBoolean(false)

  const {
    value: isAreYouAdvisorModalOpen,
    setTrue: showAreYouAdvisorModal,
    setFalse: hideAreYouAdvisorModal,
  } = useBoolean(false)

  const {
    value: isApplyToBeCohartAdvisorModalOpen,
    setTrue: showApplyToBeCohartAdvisorModal,
    setFalse: hideApplyToBeCohartAdvisorModal,
  } = useBoolean(false)

  const [isAskingRoleBannerClosed, setIsAskingRoleBannerClosed] = useShowingInfoOnce('profileAskingRoleBanner')
  const [isApplyCohartAdvisorBannerClosed, setIsApplyCohartAdvisorBannerClosed] =
    useShowingInfoOnce('applyToBeCohartAdvisorBanner')

  const toBeCreator = useCallback(async () => {
    await notifyPromise(
      async () => {
        await updateProfile({ role: 'creator' })

        trackAmplitudeCustomEvent('user_changed_role', { user_role: 'creator' })
        if (authUser?.id) identifyAmplitudeUser(authUser.id.toString(), { extra: { user_role: 'creator' } })

        hideAreYouArtistModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again
        if (shouldRedirectAfterSegmentation) router.push('/artist/summary') // redirect to the artist dashboard
      },
      {
        pending: 'Updating your role...',
        success: 'You are now an artist!',
      },
    )
  }, [hideAreYouArtistModal, router, setIsAskingRoleBannerClosed, shouldRedirectAfterSegmentation, authUser])

  const toBeIndependentAdvisor = useCallback(async () => {
    await notifyPromise(
      async () => {
        await updateProfile({ role: 'independent-advisor' })

        trackAmplitudeCustomEvent('user_changed_role', { user_role: 'independent-advisor' })
        if (authUser?.id) identifyAmplitudeUser(authUser.id.toString(), { extra: { user_role: 'creator' } })

        hideAreYouAdvisorModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again
        router.push('/homepage') // redirect to the personalized homepage
      },
      {
        pending: 'Updating your role...',
        success: 'You are now an independent advisor!',
      },
    )
  }, [hideAreYouAdvisorModal, setIsAskingRoleBannerClosed, router, authUser])

  const toBeCollector = useCallback(async () => {
    await notifyPromise(
      async () => {
        if (!authUser) return // this should not happen, since the user must be logged in to see the modal
        const { email, firstName, lastName } = authUser
        const { type, position } = await applyToBeCollector({ email, firstName, lastName })
        await mutateAuth() // refetch the user data to get the latest flags data
        hideAreYouCollectorModal()
        setIsAskingRoleBannerClosed(true) // after user choose an option, we don't show the banner again

        if (type === 'in-waitlist') {
          setCollectorWaitlistPosition(position || 1)
          showCongratCollectorWaitlistModal()
        }

        router.push('/homepage') // redirect to the personalized homepage

        // used for rendering correct success prompt
        return type
      },
      {
        pending: 'Applying to be a collector...',
        success: {
          render: ({ data }) => {
            return (data as ApplyToBeCollectorType) === 'in-waitlist'
              ? 'Your are now in the collector waitlist!'
              : 'You are now a collector!'
          },
        },
      },
    )
  }, [
    authUser,
    mutateAuth,
    hideAreYouCollectorModal,
    setIsAskingRoleBannerClosed,
    showCongratCollectorWaitlistModal,
    router,
  ])

  const showConfirmModal = useConfirmModal()

  const makeConfirmToChangeRole = useCallback(
    (onConfirm: () => unknown, options = { toCollector: false }) =>
      () => {
        const { toCollector } = options
        if (currentRoleName === 'Henry') {
          // no need to show confirm modal if the user is a Henry user
          onConfirm()
          return
        }

        showConfirmModal(
          {
            title: 'Change your account type?',
            content: (
              <div className="space-y-4 text-[14px] leading-[1.2] text-kokushoku-black">
                <p className="font-semibold">You're already registered as an {currentRoleName}.</p>
                {/* collector has different content */}
                {toCollector && (
                  <>
                    <p>If you confirm to change your account type, you will be added to our Collector waitlist. </p>
                    <p>
                      If you have made a purchase on Cohart, or approved from waitlist, you will become a Collector and
                      all your {currentRoleName}-related contents will be removed. This effect will be immediate.
                    </p>
                  </>
                )}
                {/* content for switching to artist | advisor */}
                {!toCollector && (
                  <>
                    <p>
                      If you change your account type, it will permanently delete all your {currentRoleName}-related
                      contents.
                    </p>
                    <p>This effect will be immediate.</p>
                  </>
                )}
                <p>Do you understand the risks and wish to proceed?</p>
              </div>
            ),
            description: '',
            cancelBtnText: 'Cancel',
            confirmBtnText: 'I understand',
          },
          { onConfirm },
        )
      },
    [showConfirmModal, currentRoleName],
  )

  const closeSegmentationModal = useCallback(() => {
    handleUserSegmentationPopup(false)
  }, [handleUserSegmentationPopup])

  const handleChoosingSell = useCallback(() => {
    closeSegmentationModal()
    makeConfirmToChangeRole(toBeCreator)()
  }, [closeSegmentationModal, makeConfirmToChangeRole, toBeCreator])

  const handleChoosingBuy = useCallback(() => {
    closeSegmentationModal()
    notifySuccess('Click Discover to get inspired.')
  }, [closeSegmentationModal])

  const contextValue = useMemo(() => {
    return {
      showAreYouArtistModal,
      showAreYouAdvisorModal,
      showAreYouCollectorModal,
      showApplyToBeCohartAdvisorModal,
      setIsAskingRoleBannerClosed,
      isAskingRoleBannerClosed,
      setIsApplyCohartAdvisorBannerClosed,
      isApplyCohartAdvisorBannerClosed,
    }
  }, [
    isApplyCohartAdvisorBannerClosed,
    isAskingRoleBannerClosed,
    setIsApplyCohartAdvisorBannerClosed,
    setIsAskingRoleBannerClosed,
    showApplyToBeCohartAdvisorModal,
    showAreYouAdvisorModal,
    showAreYouArtistModal,
    showAreYouCollectorModal,
  ])

  return (
    <AskingRoleModalsContext.Provider value={contextValue}>
      {children}
      {/* to be Artist */}
      {isAreYouArtistModalOpen && (
        <AreYouArtistModal onClose={hideAreYouArtistModal} onChoosingOption={makeConfirmToChangeRole(toBeCreator)} />
      )}

      {/* to be Collector */}
      {isAreYouCollectorModalOpen && (
        <AreYouCollectorModal
          hasSubmit={!!authUser?.flags.hasJoinCollectorWaitList}
          onApply={makeConfirmToChangeRole(toBeCollector, { toCollector: true })}
          onClose={hideAreYouCollectorModal}
        />
      )}

      {/* to be IA */}
      {isAreYouAdvisorModalOpen && (
        <AreYouAdvisorModal
          onClose={hideAreYouAdvisorModal}
          onApply={makeConfirmToChangeRole(toBeIndependentAdvisor)}
        />
      )}

      {/* to be CA */}
      {isApplyToBeCohartAdvisorModalOpen && <ApplyToBeCohartAdvisorModal onClose={hideApplyToBeCohartAdvisorModal} />}

      {/* congrat on collector waitlist */}
      {isCongratCollectorWaitlistModalVisible && (
        <WaitListCongratulationModal
          onClose={closeCongratCollectorWaitlistModal}
          waitListPosition={collectorWaitlistPosition}
        />
      )}

      {/* User Segmentation Modal */}
      {isUserHenry && isUserSegmentationPopupVisible && (
        <SegmentationModal
          onClose={closeSegmentationModal}
          onChoosingBuy={handleChoosingBuy}
          onChoosingSell={handleChoosingSell}
        />
      )}
    </AskingRoleModalsContext.Provider>
  )
}

export const useAskingRoleModals = () => {
  const context = useContext(AskingRoleModalsContext)
  return context
}
