import * as amplitude from '@amplitude/analytics-browser'
import { Types } from '@amplitude/analytics-browser'
import { IS_DEVELOPMENT } from 'configs/index'

import { EventName } from 'types/V3/Amplitude'

const apiKey = process.env.AMPLITUDE_API_KEY as string

class AmpliClient {
  private amplitude?: Types.BrowserClient

  get isLoaded() {
    return Boolean(this.amplitude)
  }

  async load(options?: Types.BrowserOptions) {
    if (this.amplitude) {
      console.warn('Amplitude already loaded')
      return
    }

    this.amplitude = amplitude.createInstance()
    await this.amplitude.init(apiKey, undefined, options).promise
  }

  async track(
    eventName: EventName | Types.BaseEvent,
    properties?: Record<string, Array<number | string | boolean> | number | string | boolean | null | undefined>,
    eventOptions?: Types.EventOptions,
  ) {
    if (!this.amplitude || !this.isLoaded) return
    if (IS_DEVELOPMENT) {
      console.log('Amplitude event', eventName)
      console.table(properties)
    }
    await this.amplitude.track(eventName, properties, eventOptions).promise
  }

  async identify(userId: string | undefined, options?: Types.EventOptions) {
    if (!this.amplitude) return

    let finalOptions = undefined

    const { extra, ...rest } = options || {}

    if (userId) {
      finalOptions = { ...rest, user_id: userId }
    }

    const amplitudeIdentify = new amplitude.Identify()

    for (const key in extra) {
      if (extra[key]) amplitudeIdentify.set(key, extra[key])
    }

    if (IS_DEVELOPMENT) {
      console.log('Amplitude identify', userId)
      console.table(finalOptions)
      console.table(extra)
    }

    return this.amplitude.identify(amplitudeIdentify, finalOptions)
  }
}

export const ampliClient = new AmpliClient()

export const loadAmplitudeClient = ampliClient.load.bind(ampliClient)
export const trackAmplitudeCustomEvent = ampliClient.track.bind(ampliClient)
export const identifyAmplitudeUser = ampliClient.identify.bind(ampliClient)
