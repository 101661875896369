import { IS_DEVELOPMENT } from 'configs'
import { UserProfile } from 'types/V3/User'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    USETIFUL: {
      user: {
        setId: (value: string | null) => void
        setTags: (values: Record<string, string>) => void
        clearProgress: () => void
      }
      reinitialize: () => void
    }
  }
}

export const setUpUsetiful = (params: { myProfile: UserProfile; isMobile: boolean; hasChosenRole: boolean }) => {
  const { myProfile, isMobile, hasChosenRole } = params
  setTimeout(() => {
    if (window.USETIFUL) {
      IS_DEVELOPMENT && console.log('before trigger')
      window.USETIFUL.user.setId(`${myProfile.id}`)
      window.USETIFUL.user.setTags({
        role: myProfile.role || 'henry',
        isVerified: `${myProfile.isVerified}`,
        isOnMobile: `${isMobile}`,
        hasChosenRole: `${hasChosenRole}`,
      })
      window.USETIFUL.reinitialize()
      IS_DEVELOPMENT && console.log('after trigger')
    }
  }, 300)
}

export const resetUsetiful = () => {
  if (!window.USETIFUL) return

  window.USETIFUL.user.setId(null) // remove userId
  window.USETIFUL.user.clearProgress() // remove progress
  window.USETIFUL.reinitialize()
}
