import { HomeIcon, ShoppingBagIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import useAuth from 'hooks/authentication/useAuth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import SideBarPopover from 'shared/components/popover/SideBarPopover'
import {
  AdvisorMenu,
  CollectorMenu,
  CreatorMenu,
  HenryMenu,
  SharedMenu,
} from 'shared/contexts/AppLayoutContext/configs'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'
import useAppLayout from 'shared/hooks/useAppLayout'
import { useAskingRoleModals } from 'shared/hooks/useAskingRoleModals'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'

type SideBarProps = {
  onDesktopSidebarReady?: (isReady: boolean) => void // notify the parent component that the desktop sidebar is ready
  isDesktopSidebar: boolean // indicates if the sidebar is a desktop sidebar, so we don't block scrolling when the sidebar is visible
}

const SideBar = (props: SideBarProps) => {
  const { onDesktopSidebarReady, isDesktopSidebar } = props
  const { pathname, query, asPath } = useRouter()
  const {
    toggleMobileSidebar,
    appLayout: { isMobileSidebarVisible },
  } = useAppLayout()
  const { authUser } = useAuth()
  const { showApplyToBeCohartAdvisorModal } = useAskingRoleModals()

  const { isUserCollector, isRoleMapReady, isUserHenry, isUserCreator, isUserIA, isUserCA } = useCheckingRole(
    authUser?.role,
  )
  const isOnUserProfilePage = pathname.includes('/u/[username]') // consider sub-profile pages as well (e.g. /u/[username]/moodboards, artworks, moments, ...)
  const isOnEditProfilePage = pathname.includes('/u/edit')
  const isOnProfileFoldersTab = pathname === '/u/[username]/moodboards' // check if the user is on the profile folders tab
  const isOnCurrentProfilePage = isOnUserProfilePage && authUser?.username === query.username
  const isDashboardHomepage = pathname === '/homepage'

  const activeHref = useMemo(() => {
    if (isDashboardHomepage) return '/homepage'
    if (isOnCurrentProfilePage && !isOnProfileFoldersTab) return '/profile'
    if (isOnEditProfilePage) return '/u/edit'
    const menuItems = [
      ...(isUserHenry ? HenryMenu : []),
      ...(isUserCollector ? CollectorMenu : []),
      ...(isUserCreator ? CreatorMenu : []),
      ...(isUserCA || isUserIA ? AdvisorMenu : []),
      ...SharedMenu,
    ]

    const activeItem = menuItems.find(({ regex }) => {
      return typeof regex === 'function' ? regex(authUser?.username).test(asPath) : regex.test(asPath)
    })

    return activeItem?.href ?? null
  }, [
    isOnCurrentProfilePage,
    isOnProfileFoldersTab,
    isOnEditProfilePage,
    isUserHenry,
    isUserCollector,
    isUserCreator,
    isUserCA,
    isUserIA,
    authUser?.username,
    asPath,
    isDashboardHomepage,
  ])

  // use this value to determine if we should show the desktop sidebar or not
  const isDesktopSidebarReady = Boolean(
    authUser && // currently sidebar is only available for authenticated users
      isRoleMapReady && // only show sidebar when the role map is fetched
      !(isOnUserProfilePage && !isOnCurrentProfilePage), // don't show sidebar when viewing other user's profile
  )

  useEffect(() => {
    // notify the parent component that the sidebar is ready
    onDesktopSidebarReady && onDesktopSidebarReady(isDesktopSidebarReady)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopSidebarReady])

  const createMenuItemHref = useCallback(
    (href: string | ((username?: string) => string)) => {
      return typeof href === 'string' ? href : href(authUser?.username)
    },
    [authUser?.username],
  )

  const handleMenuItemClick = useCallback(() => {
    if (isDesktopSidebar) return // dont trigger the mobile sidebar if it's a desktop sidebar
    // close the mobile sidebar when the user clicks on the menu
    toggleMobileSidebar()
  }, [isDesktopSidebar, toggleMobileSidebar])

  return (
    <div className="relative flex h-full w-full flex-col  bg-white px-4 py-3 lg:justify-between">
      <div className={'no-scrollbar w-full overflow-scroll pb-[150px] lg:pb-0'}>
        <div className="w-full space-y-1 border-b border-black/10 py-1 lg:space-y-2 lg:py-2">
          <PageItem
            isActive={activeHref === '/homepage'}
            label="Home"
            hrefLink={createMenuItemHref('/homepage')}
            icon={<HomeIcon className="h-6 w-6" />}
            onClick={handleMenuItemClick}
          />

          <PageItem
            isActive={isMobileSidebarVisible ? activeHref === '/profile' : activeHref === '/u/edit'}
            label={isMobileSidebarVisible ? 'Public Profile' : 'Edit Profile'}
            hrefLink={createMenuItemHref(isMobileSidebarVisible ? '/profile' : '/u/edit')}
            icon={<UserCircleIcon className="h-6 w-6" />}
            onClick={handleMenuItemClick}
          />
        </div>

        {isUserCreator && (
          <div className="flex w-full flex-col gap-1 border-b border-black/10 pb-1 pt-2 lg:gap-2 lg:pb-2 lg:pt-4">
            <div className="px-4 text-[10px] uppercase text-[#898788]">Artist tools</div>
            {CreatorMenu.map((menu, index) => (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            ))}
          </div>
        )}

        {isUserCollector && (
          <div className="flex w-full flex-col gap-1 border-b border-black/10 pb-1 pt-2 lg:gap-2 lg:pb-2 lg:pt-4">
            <div className="px-4 text-[10px] uppercase text-[#898788]">Collector tools</div>
            {CollectorMenu.map((menu, index) => (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            ))}
          </div>
        )}

        {(isUserIA || isUserCA) && (
          <div className="flex w-full flex-col gap-1 border-b border-black/10 pb-1 pt-2 lg:gap-2 lg:pb-2 lg:pt-4">
            <div className="px-4 text-[10px] uppercase text-[#898788]">Advisor tools</div>
            {AdvisorMenu.map((menu, index) => (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            ))}
            {isUserIA && (
              <button
                className="flex items-center justify-center rounded-[8px] bg-[#0F6BFF] py-2 text-xs font-medium text-white"
                onClick={showApplyToBeCohartAdvisorModal}
              >
                Apply to be a Cohart Advisor
              </button>
            )}
          </div>
        )}

        {isUserHenry && (
          <div className="flex w-full flex-col gap-1 border-b border-black/10 pb-1 pt-2 empty:hidden lg:gap-2 lg:pb-2 lg:pt-4">
            {HenryMenu.map((menu, index) => (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            ))}
          </div>
        )}

        {/* shared */}
        <div className="flex w-full flex-col gap-1 py-1 lg:gap-2 lg:py-2">
          {SharedMenu.map((menu, index) => {
            return (
              <PageItem
                key={index}
                isActive={activeHref === menu.href}
                label={menu.label}
                hrefLink={createMenuItemHref(menu.href)}
                icon={menu.icon}
                onClick={handleMenuItemClick}
              />
            )
          })}

          {!isUserHenry && (
            <PageItem
              isActive={activeHref === '/purchases'}
              label={'Purchases'}
              hrefLink={createMenuItemHref('/purchases')}
              icon={<ShoppingBagIcon className="h-6 w-6" />}
              onClick={handleMenuItemClick}
            />
          )}

          {/* Side Bar Popover for mobile */}
          <div className="block w-full border-t border-black/10 pt-1 lg:hidden lg:pt-2">
            <SideBarPopover isDesktopSidebar={isDesktopSidebar} isUserCreator={isUserCreator} />
          </div>
        </div>
      </div>

      {/* Side Bar Popover for desktop */}
      <div className="absolute bottom-3 left-0 hidden w-full bg-white lg:block">
        <div className="w-full border-t border-black/10 pt-1 lg:pt-2">
          <SideBarPopover isDesktopSidebar={isDesktopSidebar} isUserCreator={isUserCreator} />
        </div>
      </div>
    </div>
  )
}

export default SideBar

type PageItemProps = {
  label: string
  icon: ReactNode
  hrefLink: string
  isActive: boolean
  onClick?: () => void
  hidden?: boolean
}

const PageItem = (props: PageItemProps) => {
  const { label, icon, hrefLink, isActive, onClick, hidden } = props

  const handleMenuItemClick = useCallback(() => {
    if (hrefLink === '/artist/storefront') {
      trackAmplitudeCustomEvent('edit_storefront_clicked')
    }
    onClick && onClick()
  }, [onClick, hrefLink])

  if (hidden) return null
  return (
    <Link
      passHref
      href={hrefLink}
      className={cn(
        'flex items-center gap-2 rounded-[8px] px-4 py-2 hover:bg-[#E9E9E9]', //
        isActive && 'bg-[#E9E9E9]',
      )}
      onClick={handleMenuItemClick}
    >
      {icon}
      <div className="text-sm font-medium">{label}</div>
    </Link>
  )
}
