import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { ApplyToBeCollectorType } from '../types'

type ApplyCollectorType = {
  email: string
  firstName: string
  lastName: string
}

const applyToBeCollector = async (info: ApplyCollectorType) => {
  try {
    const { data } = await withApiErrorHandled<{ type: ApplyToBeCollectorType; position: number }>(apiClient.post)(
      '/v2/public/apply-collector',
      info,
    )

    return data.data
  } catch (error) {
    console.error(error)
    console.error('Cannot apply to be a collector')
    // we don't throw error here, because we don't want to block the user from signing up if the invitation is invalid, or something went wrong
    return { type: 'unable-to-promoted-to-collector' as ApplyToBeCollectorType, position: 0 }
  }
}

export default applyToBeCollector
