import { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react'

import { useBoolean } from 'usehooks-ts'
import { trackEvent } from 'services/analytics/google'
import signOutUser from 'views/authentication/services/signOutUser'
import useOnClickOutside from 'hooks/useOnClickOutside'
import Router from 'next/router'
import { useAskingRoleModals } from 'shared/hooks/useAskingRoleModals'
import { resetUsetiful } from 'services/analytics/usetiful'

type LogoutModalContextType = {
  showLogoutModal: () => void
}

const LogoutModalContext = createContext<LogoutModalContextType>({} as LogoutModalContextType)

type LogoutModalProviderProps = {
  children: ReactNode
}

export const LogoutModalProvider = ({ children }: LogoutModalProviderProps) => {
  const { value: logoutModal, setTrue: showLogoutModal, setFalse: hideLogoutModal } = useBoolean(false)
  const { setIsAskingRoleBannerClosed } = useAskingRoleModals()
  const setOnClickOutsideLogout = useCallback(() => {
    hideLogoutModal()
  }, [hideLogoutModal])
  const logoutRef = useRef<HTMLDivElement>(null)

  // Handlers
  const handleLogOut = useCallback(async () => {
    trackEvent({ action: 'log_out' })
    await signOutUser()
    setIsAskingRoleBannerClosed(false)

    resetUsetiful()

    hideLogoutModal()
    Router.push('/login')
    localStorage.removeItem('uploading-artwork-tip-closed') // clear history of uploading artwork tooltip
    localStorage.removeItem('showSegmentationModal') // clear history of segmentation modal
    // could be removed in the future if we have user URL link, or fixed username
  }, [hideLogoutModal, setIsAskingRoleBannerClosed])

  // Hooks
  useOnClickOutside({ ref: logoutRef, handler: setOnClickOutsideLogout })

  // Context
  const contextValue = useMemo(() => {
    return {
      showLogoutModal,
    }
  }, [showLogoutModal])

  return (
    <LogoutModalContext.Provider value={contextValue}>
      {children}
      {logoutModal && (
        <div className="fixed top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
          <div
            className="flex h-[250px] w-[350px] flex-col justify-between rounded-[24px] bg-white p-8"
            ref={logoutRef}
          >
            <h1>Are you sure you want to Log Out?</h1>
            <div className="flex w-full flex-col space-y-2">
              <button className="rounded-full border border-black bg-mint-green py-2 uppercase" onClick={handleLogOut}>
                Log Out
              </button>
              <button className="py-2" onClick={hideLogoutModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </LogoutModalContext.Provider>
  )
}

export const useLogoutModal = () => {
  const context = useContext(LogoutModalContext)
  return context
}
